import bsModal from 'bootstrap/js/dist/modal';
import sum from 'lodash/sum';

const printPageSection =
  (element, classes = []) => import('../../components/print')
    .then(module => module.printPageSection(element, classes));
export default {
  data() {
    return {
      load_contract_label: 'Charger les infos d\'un contrat',
      situation: 'couple',
      children: '1',
      child_age: '0',
      min_child_age: 'less-than-six',
      alsace_moselle: false,
      other_aid_toggle: false,
      handicap_toggle: false,
      hourlyWageSetFromInput: false,
      other_aid: '0',
      month_week: 'week',
      monthly_worked_days: '',
      monthly_hours: '',
      has_overtime: false,
      monthly_overtime: '0',
      increase_overtime: '25',
      weekly_worked_days: '5',
      weekly_hours: '40',
      yearly_weeks: '52',
      contract_ends_before_one_year: false,
      gross_daily_wage: '0',
      net_ratio_classic_france: null,
      net_ratio_classic_alsace_moselle: null,
      net_ratio_detax_france: null,
      net_ratio_detax_alsace_moselle: null,
      threshold: 0,
      social_contributions: {},
      cmgAidToggle: 'yes',
      isPublic: false,
      maxHoursYear: 744,
      isHourlyRateInRange: true,
    };
  },
  computed: {
    alsace_moselle_french() {
      return this.alsace_moselle ? 'Oui' : 'Non';
    },
    children_i() {
      return Number(this.children);
    },
    child_age_french() {
      const date = new Date();
      const limitBirthYear = date.getFullYear() - 3;
      return {
        0: 'moins de 3 ans',
        2: '3 ans (né entre le 1/1/' + limitBirthYear + ' et le 31/8/' + limitBirthYear + ')',
        3: '3 à 5 ans',
        6: '6 ans ou plus',
      }[this.child_age];
    },
    child_age_option() {
      return this.child_age === '2' ? '0' : this.child_age;
    },
    min_child_age_french() {
      return { 'less-than-six': 'moins de 6 ans', 'six-or-more': 'Plus de 6 ans' }[this.min_child_age];
    },
    situation_french() {
      return this.situation === 'single' ? 'parent seul' : 'en couple';
    },
    monthly_worked_days_f() {
      return parseFloat(this.monthly_worked_days.replace(',', '.'));
    },
    monthly_hours_f() {
      return parseFloat(this.monthly_hours.replace(',', '.'));
    },
    increase_overtime_i() {
      return Number(this.increase_overtime);
    },
    weekly_worked_days_f() {
      return parseFloat(this.weekly_worked_days.replace(',', '.'));
    },
    weekly_hours_f() {
      return parseFloat(this.weekly_hours.replace(',', '.'));
    },
    yearly_weeks_f() {
      const result = parseFloat(this.yearly_weeks.replace(',', '.'));
      if (this.contract_ends_before_one_year) {
        return result;
      } else {
        return Math.floor(result);
      }
    },
    other_aid_f() {
      return this.other_aid_toggle ? parseFloat(this.other_aid.replace(',', '.')) : 0;
    },
    net_ratio_detax() {
      return this.alsace_moselle ? this.net_ratio_detax_alsace_moselle : this.net_ratio_detax_france;
    },
    gross_mensualization() {
      return this.gross_mensualization_regular + this.gross_mensualization_overtime;
    },
    gross_hourly_wage_f() {
      return parseFloat(this.gross_hourly_wage.replace(',', '.'));
    },
    net_mensualization_classic() {
      return this.gross_mensualization_regular * this.net_ratio_classic;
    },
    net_mensualization_detax() {
      return this.has_overtime ? (this.gross_mensualization_overtime * this.net_ratio_detax) : 0;
    },
    net_mensualization() {
      return this.net_mensualization_classic + this.net_mensualization_detax;
    },
    net_monthy_overtime_increase() {
      return this.gross_monthy_overtime_increase * this.net_ratio_detax;
    },
    net_ratio_classic() {
      return this.alsace_moselle ? this.net_ratio_classic_alsace_moselle : this.net_ratio_classic_france;
    },
    net_partial_year_vacations_monthly() {
      return this.gross_partial_year_vacations_monthly * this.net_ratio_classic;
    },
    cmg_daily_salary_gross() {
      return (this.gross_mensualization + this.gross_monthy_overtime_increase) /
        Math.ceil(this.monthly_worked_days_f);
    },
    social_contributions_employee() {
      const amounts = [];

      for (const contribution in this.social_contributions) {
        amounts.push(this.social_contribution_amount(contribution, 'employee'));
      }

      return sum(amounts);
    },
    is_weekly_worked_days() {
      return this.weekly_worked_days !== '' && this.weekly_worked_days_f >= 0 && this.weekly_worked_days_f <= 7;
    },
    is_full_year() {
      return parseInt(this.yearly_weeks) === 52;
    },
    is_week() {
      return this.month_week === 'week';
    },
    is_month() {
      return this.month_week === 'month';
    },
    regular_hours() {
      return this.monthly_hours_f - (this.has_overtime ? this.monthly_overtime_f : 0);
    },
    min_child_too_old() {
      return this.min_child_age === 'six-or-more';
    },
    high_mensualization() {
      return this.cmg_daily_salary_gross > this.threshold;
    },
    social_contributions_employer() {
      const amounts = [];

      for (const contribution in this.social_contributions) {
        amounts.push(this.social_contribution_amount(contribution, 'employer'));
      }

      return sum(amounts);
    },
    is_monthly_hours() {
      return this.monthly_hours !== '' && this.monthly_hours_f >= 0 && this.monthly_hours_f <= this.maxHoursYear;
    },
    is_monthly_worked_days() {
      return this.monthly_worked_days !== '' && this.monthly_worked_days_f >= 0 && this.monthly_worked_days_f <= 31;
    },
    is_monthly_overtime() {
      return this.has_overtime && this.monthly_overtime !== '' && this.monthly_overtime_f >= 0 &&
        this.monthly_overtime_f <= 140;
    },
    partial_year_vacations_10_percent_yearly_gross() {
      return this.gross_mensualization * 12 * 0.1;
    },
    /**
     * le nombre de jour acquis multiplié par la valeur d'un jour de congé
     * @returns {number}
     */
    partial_year_vacations_maintains_salary_yearly_gross() {
      return this.partial_acquired_days * this.daily_maintain_vacation_maintain_gross;
    },
    /**
     * 2,5 jours de congés acquis toutes les 4 semaines travaillées, et arrondi au supérieur
     * @returns {number}
     */
    partial_acquired_days() {
      return Math.ceil(Math.min((2.5 * (this.yearly_weeks_f / 4)), 30));
    },
    /**
     * la valeur d'un jour de congé est obtenu par le coût par semaine divisé par 6
     * (6 jours ouvrables par semaine)
     * @returns {number}
     */
    daily_maintain_vacation_maintain_gross() {
      return (this.weekly_maintain_vacation_maintain_gross) / 6.0;
    },
    /**
     * la valeur d'une semaine de congés est obtenue par mensualisation ramenée à l'année,
     * divisée par le nombre de semaines effectives.
     * @returns {number}
     */
    weekly_maintain_vacation_maintain_gross() {
      return (this.gross_mensualization * 12 / this.yearly_weeks_f);
    },
    gross_partial_year_vacations_monthly() {
      return Math.max(
        this.partial_year_vacations_10_percent_yearly_gross,
        this.partial_year_vacations_maintains_salary_yearly_gross
      ) / 12.0;
    },
    tranches() {
      const salaryCaps = {
        1: { from: 23903, to: 53119 },
        2: { from: 27295, to: 60659 },
        3: { from: 30687, to: 68199 },
        more: { from: 3392, to: 7540 },
      };

      let fromRange = this.children_i > 3
        ? salaryCaps[3].from + ((this.children_i - 3) * salaryCaps.more.from)
        : salaryCaps[this.children_i].from;
      if (this.situation === 'single') fromRange = Math.round(fromRange * 1.4);

      let toRange = this.children_i > 3
        ? salaryCaps[3].to + ((this.children_i - 3) * salaryCaps.more.to)
        : salaryCaps[this.children_i].to;
      if (this.situation === 'single') toRange = Math.round(toRange * 1.4);
      // Return multi dimensional array to build table

      return [
        [
          'De 0 à ' + fromRange + '\xa0€',
          this.to_money(this.maximum_amount(0)),
          this.to_money(this.cmg_amount(0)),
          this.to_money(this.remaining_amount(0)),
          this.to_money(this.taxes_credit(0) / 12),
          this.to_money(this.taxes_credit(0)),
          this.to_money(this.amount(0)),
          this.to_money(this.daily_amount(0)) + '\xa0/\xa0jour',
          this.to_money(this.hourly_amount(0)) + '\xa0/\xa0heure',
        ],
        [
          'De ' + fromRange + ' à ' + toRange + '\xa0€',
          this.to_money(this.maximum_amount(1)),
          this.to_money(this.cmg_amount(1)),
          this.to_money(this.remaining_amount(1)),
          this.to_money(this.taxes_credit(1) / 12.0),
          this.to_money(this.taxes_credit(1)),
          this.to_money(this.amount(1)),
          this.to_money(this.daily_amount(1)) + '\xa0/\xa0jour',
          this.to_money(this.hourly_amount(1)) + '\xa0/\xa0heure',
        ],
        [
          'Supérieurs à ' + toRange + '\xa0€',
          this.to_money(this.maximum_amount(2)),
          this.to_money(this.cmg_amount(2)),
          this.to_money(this.remaining_amount(2)),
          this.to_money(this.taxes_credit(2) / 12.0),
          this.to_money(this.taxes_credit(2)),
          this.to_money(this.amount(2)),
          this.to_money(this.daily_amount(2)) + '\xa0/\xa0jour',
          this.to_money(this.hourly_amount(2)) + '\xa0/\xa0heure',
        ],
      ];
    },
  },
  methods: {
    /**
     * We convert the displayed value that contains a comma into a float with two decimal point
     */
    shownStringToFloat(val) {
      if (typeof val === 'string') {
        const convertedValue = parseFloat(val.replace(',', '.'));
        return convertedValue.toFixed(2);
      }
      return val;
    },
    toggleMW() {
      if (this.month_week === 'month') this.month_from_week();
      if (this.month_week === 'week') this.week_from_month();

      const that = this;
      setTimeout(function() {
        that.check_errors();
      });
    },
    maximum_amount(range_index) {
      const age = this.child_age_option;
      const cmgMaxAmount = [
        {
          0: 538.27,
          3: 269.14,
          6: 0,
        },
        {
          0: 339.42,
          3: 169.73,
          6: 0,
        },
        {
          0: 203.62,
          3: 101.81,
          6: 0,
        },
      ];

      return cmgMaxAmount[range_index][age] * (this.situation === 'single' ? 1.3 : 1) * (this.handicap_toggle ? 1.3 : 1);
    },
    month_from_week() {
      this.monthly_worked_days = this.is_weekly_worked_days && this.is_yearly_weeks
        ? this.toFloatForDisplay(this.weekly_worked_days_f *
          (this.yearly_weeks_f / 12), 4)
        : '';
      this.monthly_hours = this.is_weekly_hours && this.yearly_weeks
        ? this.toFloatForDisplay(this.weekly_hours_f *
          (this.yearly_weeks_f / 12), 4)
        : '';

      if (this.has_overtime) {
        const overtime_weekly = this.weekly_overtime_f;
        const overtime_daily = overtime_weekly / this.weekly_worked_days_f;
        const overtime_monthly = overtime_daily * this.monthly_worked_days_f;

        this.monthly_overtime = isNaN(overtime_monthly) ? '0' : this.toFloatForDisplay(overtime_monthly, 4);
      } else this.monthly_overtime = '0';
    },
    social_contribution_base(name) {
      const contribution = this.social_contributions[name];

      switch (contribution.base) {
        case 'total':
          return this.gross_total;
        case 'base_normal_9825':
          return (this.gross_total - this.social_contributions_detax_base()) * 0.9825;
        case 'base_detax_9825':
          return this.social_contributions_detax_base() * 0.9825;
        case 'base_exonerated_heures_sup_comp':
          return this.social_contributions_detax_base();
        case 'base_social':
          return 0;
        default:
          return this.gross_total;
      }
    },
    social_contributions_detax_base() {
      return this.gross_mensualization_overtime + this.gross_monthy_overtime_increase;
    },
    social_contribution_amount(name, role) {
      if (!this.social_contribution_is_relevant(name)) return 0;

      const rate = this.social_contributions[name][role + '_rate'];
      if (rate === 0) return 0;

      const base = this.social_contribution_base(name);

      const amount = base * (rate / 100.0);
      const maximumAmount = this.social_contributions[name][role + '_max'] ?? amount;

      return Math.min(amount, maximumAmount);
    },
    set_gross_hourly_wage(value) {
      this.gross_hourly_wage = value;
      this.recreate_gross_hourly_wage_slider();
    },
    monthly_taxes_credit(i) {
      return this.taxes_credit(i) / 12;
    },
    amount(i) {
      return this.remaining_amount(i) - this.monthly_taxes_credit(i);
    },
    daily_amount(i) {
      return this.amount(i) / this.monthly_worked_days_f;
    },
    hourly_amount(i) {
      return this.amount(i) / this.monthly_hours_f;
    },
    to_money(val) {
      return isNaN(val) ? '?' : this.toFloatForDisplay(Math.round10(val, -2), 2) + '\xa0€';
    },
    to_decimal4(val) {
      return isNaN(val) ? '?' : Math.round10(val, -4).toString().replace('.', ',');
    },
    destroy_gross_hourly_wage_slider() {
      this.slider.destroy();
    },
    recreate_gross_hourly_wage_slider() {
      this.destroy_gross_hourly_wage_slider();
      this.create_gross_hourly_wage_slider();
    },
    addBlur() {
      this.$nextTick(function() {
        $('.blur').addClass('blurring');
      });
    },
    print() {
      printPageSection('#cmg-simulator__print', ['cmg-simulator']);
    },
    toFloatForDisplay(float, digits) {
      return float.toFixed(digits).replace('.', ',');
    },
    adjustHourlyRate(adjustment) {
      let hourlyWage = this.gross_hourly_wage_f;
      hourlyWage = adjustment === 'increase' ? hourlyWage + 0.01 : hourlyWage - 0.01;
      this.slider.set(hourlyWage);
      hourlyWage = this.toFloatForDisplay(hourlyWage, 2);
      this.gross_hourly_wage = hourlyWage;
      this.grossHourlyWageInput = hourlyWage;
    },
    updateHourlyWage() {
      // we set this variable so that the event that updates the slider
      // does not interfere with the input itself
      this.hourlyWageSetFromInput = true;
      const hourlyWage = parseFloat(this.grossHourlyWageInput.replace(',', '.'));
      if (hourlyWage <= this.maximum_gross_hourly_wage || hourlyWage >= this.minimum_gross_hourly_wage) {
        this.gross_hourly_wage = this.toFloatForDisplay(hourlyWage, 2);
        this.slider.set(hourlyWage);
      }
      this.hourlyWageSetFromInput = false;
    },

  },
  watch: {
    maximum_gross_hourly_wage() {
      this.recreate_gross_hourly_wage_slider();
    },
    month_week(newVal, oldVal) {
      this.toggleMW();
      if (oldVal === 'other' && this.isPublic) {
        this.addBlur();
      }
    },
    weekly_worked_days() {
      this.month_from_week();
      this.check_errors();
    },
    yearly_weeks() {
      if (this.month_week === 'week') this.month_from_week();

      // via le cmg_load, on peut être amené à charger un contrat de type ends_before_one_year.
      // on a du coup été obligé d'autoriser les virgules sur le champs.
      // l'attribut yearly_weeks_f est remis en integer si nécessaire.
      // On le ré-injecte donc ici à nouveau ensuite
      this.yearly_weeks = isNaN(this.yearly_weeks_f) ? '' : this.to_decimal4(this.yearly_weeks_f).toString().replace('.', ',');
      this.check_errors();
    },
    monthly_worked_days() {
      this.check_errors();
    },
    monthly_hours() {
      this.check_errors();
    },
    monthly_overtime() {
      this.check_errors();
    },
    min_child_age() {
      if (this.min_child_age === 'six-or-more') {
        this.child_age = 6;
      }
    },
    isPublic(val) {
      if (val) {
        this.addBlur();
        $('body').on('click', '.blurring', function() {
          const modalElement = document.getElementById('member-only-result');
          const modal = bsModal.getOrCreateInstance(modalElement);
          modal.show();
        });
      }
    },
    alsace_moselle() {
      const $otherRegionInput = $('input.other_regions');
      $otherRegionInput.prop('checked', this.alsace_moselle);
      $otherRegionInput.change();
    },
    handicap_toggle() {
      const $handicapToggle = $('input.handicap_toggle');
      $handicapToggle.prop('checked', this.handicap_toggle);
      $handicapToggle.change();
    },
    other_aid_toggle() {
      const $otherAidToggle = $('input.other_aid_toggle');
      $otherAidToggle.prop('checked', this.other_aid_toggle);
      $otherAidToggle.change();
    },
    grossHourlyWageInput(val) {
      const floatValue = this.shownStringToFloat(val);
      this.isHourlyRateInRange = !(floatValue > this.maximum_gross_hourly_wage || floatValue < this.minimum_gross_hourly_wage);
      $('.wrapper_input.simulator_gross_hourly_wage .input-group').toggleClass('is-invalid', !this.isHourlyRateInRange || this.cmg_not_available);
    },
  },
};

// reference to external jquery
import Vue from 'vue/dist/vue.min.js';
import cmgSimulator from '../calculators/mixins/cmgSimulator';
import { dayjs } from '../components/dayjs-localized';

document.addEventListener('turbo:load', function() {
  window.CmgSimulatorGed = undefined;
  const gedCmgSimulatorElement = document.getElementById('paje-simulator-ged');

  if (gedCmgSimulatorElement) {
    window.CmgSimulatorGed = new Vue({
      el: '#paje-simulator-ged',
      mixins: [cmgSimulator],
      data() {
        return {
          shared_cost_toggle: false,
          shared_cost: '0',
          has_overtime_fifty: false,
          monthly_overtime_fifty: '0',
          increase_overtime_fifty: '50', // for above 48h to 50h
          gross_hourly_wage: '10.35',
          grossHourlyWageInput: '10,35',
          minimum_gross_hourly_wage: 10.35,
          maximum_gross_hourly_wage: 40.00,
          maxNormalWorkingHours: 40,
          maxOvertimeForty: 48,
          maxOvertimeFifty: 50,
          firstEmployeeDeclaration: false,
          taxesCredit: {
            increase: 1500,
            firstYear: {
              min: 15000,
              max: 18000,
            },
            otherYears: {
              min: 12000,
              max: 15000,
            },
          },
          socialContributionMaximumCmgAmount: {
            0: 463,
            3: 232,
            6: 0,
          },
        };
      },
      watch: {
        firstEmployeeDeclaration: function() {
          const $item = $('.cmg-simulator-ged__first-employee-declaration');
          $item.prop('checked', this.firstEmployeeDeclaration);
          $item.change();
        },
        shared_cost_toggle: function() {
          const $item = $('input.cmg-simulator-ged__shared-cost-toggle');
          $item.prop('checked', this.shared_cost_toggle);
          $item.change();
        },
        weekly_hours: function() {
          this.month_from_week();

          this.has_overtime = this.weekly_hours_f > this.maxNormalWorkingHours;
          this.has_overtime_fifty = this.weekly_hours_f > this.maxOvertimeForty;

          this.check_errors();
        },
        has_overtime: function() {
          const $item = $('.toggle.planned_overtime');
          $item.prop('checked', this.has_overtime);
          $item.change();
          const that = this;
          setTimeout(function() {
            that.check_errors();
          });
        },
        children() {
          this.updateTooltip();
        },
        gross_total() {
          this.updateTooltip();
        },
        social_contributions_employer() {
          this.updateTooltip();
        },
        situation_french() {
          this.updateTooltip();
        },
        child_age_french() {
          this.updateTooltip();
        },
        tranches() {
          this.updateTooltip();
        },
        child_age(value) {
          if (value === '6') {
            this.min_child_age = 'six-or-more';
          }
        },
      },
      computed: {
        monthly_overtime_f: function() {
          return this.monthlyOvertimeForty + this.monthlyOvertimeFifty;
        },
        monthlyOvertimeForty: function() {
          return this.is_month
            ? parseFloat(this.monthly_overtime.replace(',', '.'))
            : (this.weekly_overtime_f / this.weekly_worked_days_f * this.monthly_worked_days_f);
        },
        monthlyOvertimeFifty: function() {
          return this.is_month
            ? parseFloat(this.monthly_overtime_fifty.replace(',', '.'))
            : (this.weekly_overtime_fifty_f / this.weekly_worked_days_f * this.monthly_worked_days_f);
        },
        weekly_overtime_f: function() {
          return this.has_overtime ? this.weekly_hours_f - this.maxNormalWorkingHours - this.weekly_overtime_fifty_f : 0;
        },
        weekly_overtime_fifty_f: function() {
          return this.has_overtime_fifty ? this.weekly_hours_f - this.maxOvertimeForty : 0;
        },
        increase_overtime_fifty_i: function() {
          return Number(this.increase_overtime_fifty);
        },
        dividedGrossHourlyWage: function() {
          return this.shared_cost_toggle
            ? parseFloat(this.shared_cost.replace(',', '.')) / 100 * this.gross_hourly_wage_f
            : this.gross_hourly_wage_f;
        },
        net_hourly_wage: function() {
          return this.dividedGrossHourlyWage * this.net_ratio_classic;
        },
        gross_mensualization_regular: function() {
          return this.regular_hours * this.dividedGrossHourlyWage;
        },
        gross_mensualization_overtime: function() {
          return this.has_overtime ? this.monthly_overtime_f * this.dividedGrossHourlyWage : 0;
        },
        gross_monthy_overtime_increase: function() {
          let monthly_overtime_increase = this.has_overtime
            ? this.monthlyOvertimeForty * this.dividedGrossHourlyWage * this.increase_overtime_i / 100
            : 0;
          monthly_overtime_increase += this.has_overtime_fifty
            ? this.monthlyOvertimeFifty * this.dividedGrossHourlyWage * this.increase_overtime_fifty_i / 100
            : 0;
          return monthly_overtime_increase;
        },
        cmg_not_available: function() {
          return this.cmgAidToggle === 'no' || this.min_child_too_old;
        },
        /**
         * Abattement de charge patronales
         * this charge of 2 € is applied on 90% of the total normal hours worked and 100% on overtime worked
         * this value is capped to the remaining of the employer social contribution
         * @returns {number}
         */
        employerContributionsReduction: function() {
          const possibleEmployerReduction = Math.ceil(this.regular_hours * 0.9 * 2) + this.monthly_overtime_f * 2;
          const maxPossibleReduction = Math.max(this.social_contributions_employer - this.social_contributions_credit, 0);
          return Math.min(possibleEmployerReduction, maxPossibleReduction);
        },
        maxSocialContributionCmgAmount: function() {
          return this.socialContributionMaximumCmgAmount[parseInt(this.child_age_option)];
        },
        is_calculable: function() {
          if (this.month_week === 'month') {
            if (!this.is_monthly_hours || !this.is_monthly_worked_days) return false;
            if (this.has_overtime && !this.is_monthly_overtime) return false;
            if (this.has_overtime && (this.monthly_overtime_f > this.monthly_hours_f)) return false;
            if (!this.is_weekly_hours || !this.is_weekly_worked_days || !this.is_yearly_weeks) return false;
          } else if (this.month_week === 'week') {
            if (!this.is_weekly_hours || !this.is_weekly_worked_days || !this.is_yearly_weeks) return false;
          }

          return this.month_week !== 'other';
        },
        is_weekly_hours: function() {
          return this.weekly_hours != '' && this.weekly_hours_f >= 0 && this.weekly_hours_f <= 50;
        },
        is_yearly_weeks: function() {
          return this.yearly_weeks !== '' && this.yearly_weeks_f <= 52;
        },
        /**
         * As in GED we should be in full year the vacation paid should always be in the monthly salary
         */
        net_vacations_monthly: function() {
          return 0;
        },
        gross_vacations_monthly: function() {
          return 0;
        },
        gross_total() {
          return this.gross_mensualization + this.gross_vacations_monthly + this.gross_monthy_overtime_increase;
        },
        total_amount: function() {
          return this.gross_total + this.social_contributions_employer;
        },
        /**
         * Social contribution credit
         * In terms of GED we only take the social contribution of the employer which can be paid up to 50%
         * and to a maximum of 463 € depending on the child age
         * @returns {number|number}
         */
        social_contributions_credit: function() {
          return this.cmg_not_available
            ? 0
            : Math.min(this.social_contributions_employer / 2,
              this.maxSocialContributionCmgAmount);
        },
        /**
         * The tax credit takes 50% of your expenses and the expenses are capped depending on cases.
         * It may be increased for the number of children that the parent have
         * For the first year of declaration the ceil must be increased
         * @returns {number}
         */
        creditTaxCeil: function() {
          const increaseAmount = this.children * this.taxesCredit.increase;
          const amountFirstYear = Math.min((this.taxesCredit.firstYear.min + increaseAmount),
            this.taxesCredit.firstYear.max);
          const amountOtherYears = Math.min((this.taxesCredit.otherYears.min + increaseAmount),
            this.taxesCredit.otherYears.max);
          return (this.firstEmployeeDeclaration ? amountFirstYear : amountOtherYears) / 2;
        },
      },
      methods: {
        cmg_amount: function(range_index) {
          let amount;
          if (this.child_age_option === '6' || this.cmg_not_available) {
            amount = 0;
          } else {
            const divideMaxAMount = this.cmgAidToggle === 'partial' ? 2 : 1;
            const maximumAmount = this.maximum_amount(range_index) / divideMaxAMount;
            const perceivableAmount = ((
              this.net_mensualization +
                this.net_monthy_overtime_increase +
                this.net_vacations_monthly
            ) * 0.85) / divideMaxAMount;
            amount = Math.min(maximumAmount, perceivableAmount);
          }
          return amount;
        },
        remaining_amount: function(i) {
          return (
            this.total_amount -
              this.social_contributions_credit -
              this.cmg_amount(i) -
              this.employerContributionsReduction -
              this.other_aid_f
          );
        },
        /**
         * Taxes credit
         * We need to get the total of the expenses over the year for the employer
         * Total salary + Total contribution by employer - Abatement Employer charges - Aids for social contribution - CMG aids - other aids
         * Only 50% of the expenses will be used as taxes credit
         * This calculation is made for the year and the amount that can be paid is ceiled to 12000 € depending on cases
         * @param i
         * @returns {number}
         */
        taxes_credit: function(i) {
          return Math.min(
            Math.max(
              (
                this.gross_total +
                      this.social_contributions_employer -
                      this.employerContributionsReduction -
                      this.social_contributions_credit -
                      this.cmg_amount(i) -
                      this.other_aid_f
              ) * 12 / 2,
              0
            ), this.creditTaxCeil
          );
        },
        week_from_month: function() {
          if (this.is_monthly_overtime) {
            const weekly_overtime =
                this.is_weekly_worked_days && this.is_monthly_worked_days
                  ? ((this.monthly_overtime_f / this.monthly_worked_days_f) * this.weekly_worked_days_f)
                  : 0;

            this.weekly_hours = this.is_monthly_hours && this.is_weekly_worked_days && this.is_monthly_worked_days
              ? (
                  (
                    (this.monthly_hours_f - this.monthly_overtime_f) /
                        this.monthly_worked_days_f * this.weekly_worked_days_f
                  ) + weekly_overtime
                ).toFixed(2).toString().replace('.', ',')
              : '40';
          } else {
            this.weekly_hours = this.is_monthly_hours && this.is_weekly_worked_days && this.is_monthly_worked_days
              ? (
                  this.monthly_hours_f / this.monthly_worked_days_f * this.weekly_worked_days_f
                ).toFixed(2).toString().replace('.', ',')
              : '40';
          }
        },
        social_contribution_is_relevant: function(name) {
          const contribution = this.social_contributions[name];

          if (contribution.care_type === 'assmat') return false;
          if (typeof contribution.alsace_moselle_specific !== 'undefined' && !this.alsace_moselle) return false;
          if (typeof contribution.start_date !== 'undefined' && dayjs() < dayjs(contribution.start_date)) return false;
          if (typeof contribution.end_date !== 'undefined' && dayjs() > dayjs(contribution.end_date)) return false;

          return true;
        },
        check_errors: function() {
          $('.wrapper_input.simulator_weekly_worked_days .input-group').toggleClass('is-invalid', !this.is_weekly_worked_days);
          $('.wrapper_input.simulator_weekly_hours .input-group').toggleClass('is-invalid', !this.is_weekly_hours);
          $('.wrapper_input.simulator_yearly_weeks .input-group').toggleClass('is-invalid', !this.is_yearly_weeks);
          $('.wrapper_input.simulator_monthly_worked_days .input-group').toggleClass('is-invalid', !this.is_monthly_worked_days);
          $('.wrapper_input.simulator_monthly_hours .input-group').toggleClass('is-invalid', !this.is_monthly_hours);
          $('.wrapper_input.simulator_monthly_overtime .input-group').toggleClass('is-invalid', !this.is_monthly_overtime);
        },
        create_gross_hourly_wage_slider: function() {
          const that = this;
          // Non-linear slider : on fait en sorte que le taux horaire puisse avoir un maximum très haut, sans impacter l'ensemble du slider
          this.slider = noUiSlider.create(document.getElementById('paje-simulator-slider'), {
            start: this.gross_hourly_wage_f,
            tooltips: [true],
            connect: 'lower',
            range: {
              min: this.minimum_gross_hourly_wage,
              '75%': this.minimum_gross_hourly_wage * 1.5,
              max: this.maximum_gross_hourly_wage,
            },
            step: 0.01,
            format: {
              to: function(value) {
                return '<b>' + that.to_money(value) + '</b><br /> ' +
                    '<span class=\'font--xs\'>(' + that.to_money(value * that.net_ratio_classic) + ' net)</span>';
              },
              from: function(value) {
                return value;
              },
            },
          });
          $('#simulator_gross_hourly_wage').val(this.minimum_gross_hourly_wage);
          this.slider.on('update', function(label, key, value) {
            that.gross_hourly_wage = that.toFloatForDisplay(value[0], 2);
            if (!that.hourlyWageSetFromInput) {
              that.grossHourlyWageInput = that.gross_hourly_wage;
            }
          });
        },
        updateTooltip() {
          const tooltips = [
            {
              elementId: '#cmg-simulator-ged__total-monthly-payment',
              contentId: '#cmg-simulator-ged__total-monthly-payment-details',
            },
            {
              elementId: '#cmg-simulator-ged__aids-link',
              contentId: '#cmg-simulator-ged__aids-details',
            },
          ];
          tooltips.forEach(tooltip => {
            setTimeout(function() {
              $(tooltip.elementId).attr(
                'data-body',
                $(tooltip.contentId).html()
              );
            }, 500);
          });
        },
      },
      mounted() {
        this.$nextTick(function() {
          this.toggleMW();
          this.month_from_week();
          this.create_gross_hourly_wage_slider();
        });

        this.updateTooltip();
      },
    });
  }
});
